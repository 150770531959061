@import url(./assets/font/index.css);

html,
body {
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: DINPro, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}
div {
  box-sizing: border-box;
}
.title {
  color: #c62a1d;
  font-family: DINProBlack;
  text-transform: uppercase;
}
.desc {
  color: #fff;
}
