/* @font-face
{
    font-family: DINPro;
    src: url(./DINPro-CondBold.otf);
} */
@font-face {
  font-family: DINPro;
  src: url(./DINPro-Cond.otf);
}

@font-face {
  font-family: DINProBlack;
  src: url(./DINPro-CondBlack.otf);
}
